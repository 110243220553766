.dalamProses {
  padding: 5px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  width: 100px;
  background-color: rgb(80, 80, 255);
  margin-bottom: 5px;
}

.selesai {
  padding: 5px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  width: 100px;
  background-color: rgb(0, 216, 0);
  margin-bottom: 5px;
}

.meninggal {
  padding: 5px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  width: 100px;
  background-color: rgb(255, 75, 75);
  margin-bottom: 5px;
}

.rawatJalan {
  padding: 5px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  width: 100px;
  background-color: rgba(255, 150, 75, 0.934);
  margin-bottom: 5px;
}

.rawatInap {
  padding: 5px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  width: 100px;
  background-color: rgba(255, 253, 136, 0.934);
  margin-bottom: 5px;
}

.rujuk {
  padding: 5px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  width: 100px;
  background-color: rgba(195, 162, 255, 0.934);
  margin-bottom: 5px;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #F81 !important;
}