.wrappField {
  padding-bottom: 20px !important;
}

.wrappLabel {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
  font-size: 1rem;
}

.wrappTimePicker {
  padding-bottom: 21px !important;
  width: 100%;
}

.wrappFieldTujuanPoli {
  padding-bottom: 50px !important;
}

.jenisAsuransi {
  padding-bottom: 50px !important;
}

.tujuanPoli {
  padding-bottom: 20px !important;
}

.containerBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;
}

.wrappJenisPasien {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  margin-bottom: 15px !important;
}

.wrappJenisPasien .label {
  margin-right: 10px;
}