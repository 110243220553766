.containerSidebar {
  background-color: #262b40 !important;
  color: #FFF;
  width: 240px;
  height: 100vh;
}

.containerList {
  list-style: none;
}

.title {
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 10px;
}

.title-logout {
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 70px 10px 0px 10px;
}

.title-active {
  background-color: #F81 !important;
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 10px;
  border-radius: 8px;
}


.title:hover {
  background-color: #313752;
  cursor: pointer;
}

.title-logout:hover {
  background-color: #313752;
  cursor: pointer;
}